/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('Outfit/static/Outfit-Thin.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('Outfit/static/Outfit-ExtraLight.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('Outfit/static/Outfit-Light.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('Outfit/static/Outfit-Regular.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('Outfit/static/Outfit-Medium.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('Outfit/static/Outfit-SemiBold.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('Outfit/static/Outfit-Bold.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('Outfit/static/Outfit-ExtraBold.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('Outfit/static/Outfit-Black.ttf') format('truetype');
}
//Noto Sans
/* cyrillic-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('notoSans/NotoSans-Thin.ttf') format('woff2');
}
/* cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('notoSans/NotoSans-ExtraLight.ttf') format('woff2');
}
/* devanagari */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('notoSans/NotoSans-Light.ttf') format('woff2');
}
/* greek-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('notoSans/NotoSans-Regular.ttf') format('woff2');
}
/* greek */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('notoSans/NotoSans-Medium.ttf') format('woff2');
}
/* vietnamese */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('notoSans/NotoSans-SemiBold.ttf') format('woff2');
}
/* latin-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('notoSans/NotoSans-Bold.ttf') format('woff2');
}
/* latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('notoSans/NotoSans-ExtraBold.ttf') format('woff2');
}
/* cyrillic-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('notoSans/NotoSans-Black.ttf') format('woff2');
}

//Roboto

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('roboto/Roboto-Thin.ttf') format('woff2');
}/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('roboto/Roboto-Light.ttf') format('woff2');
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('roboto/Roboto-Regular.ttf') format('woff2');
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('roboto/Roboto-Medium.ttf') format('woff2');
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('roboto/Roboto-Bold.ttf') format('woff2');
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('roboto/Roboto-Black.ttf') format('woff2');
}