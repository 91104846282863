// Color palette
$primary: #59ACF9;
$primary-shade1: hsl(208, 85%, 67%);
$primary-shade2: #84BEF4;
$secondary: #F24182;
$secondary-shade1: #F24182;
$secondary-shade2: #F571AE;
$secondary-button:#2ebe11;
$success: #47d881;
$warning: #eca336;
$muted: #c0c0c0;
$danger: #bc2b2b;
$default: #ffffff;

// sizes
$xsmall: 10px;
$small: 14px;
$medium: 16px;
$large: 20px;
$xlarge: 26px;
$xxlarge: 32px;
$xxxlarge: 40px;

$bold: 700;
$light: 300;
$fmedium: 500;
$regular: 400;