@import '../../assets/css/variables.scss';

.property {
  margin: 24px;
  display: flex;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(255, 255, 255);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 2px;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 8px, rgba(0, 0, 0, 0.12) 0px 5px 8px;
  cursor: pointer;

  img {
    margin: 0px;
    padding: 0px;
    box-shadow: none;
    width: 265px;
    height: 190px;
    object-fit: cover;
  }

  .detail-container-title {
    margin: auto;

    p {
      width: 100%;
      margin-top: 20px;
      text-align: center;
      font-weight: bold;
      color: rgb(121, 121, 121);
      font-size: $medium;
    }
  }

  .detail-container {
    margin-top: 20px;

    p {
      width: 100%;
      text-align: center;
      color: rgb(121, 121, 121);
      font-size: $small;
    }
  }
}

.img-carousel {
  margin: auto;
  max-height: 230px;
  max-width: 1080px;
  min-width: 100%;
  min-height: 180px;
  align-self: center;
  object-fit: cover;
}

.container-img {
  position: relative;
  display: inline-block;
  text-align: center;

  img {
    width: 180px;
    height: 100%;
    filter: brightness(36%);
  }

  @media (max-width: 320px) {
    img {
      width: 120px;
    }
  }

  p {
    margin-left: 6%;
    font-size: 14px;
  }
}

.container-description {
  display: grid;
  margin: auto;
  margin-right: -2px;
  text-align: end;
  width: 150px;

  p {
    margin-right: 6%;
    font-size: 14px;
  }
}


.list-property {
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  @media (max-width: 320px) {
    height: 120px;
  }
}

.container-text-img {
  display: grid;
  width: 150px;
  margin-right: auto;
  text-align: start;
  position: absolute;
  top: 14px;
  color: white;
  p {
    margin-top: -10px;
  }
}

.header-carousel {
  text-align: center;
  padding-top: 20px;
  height: 72px;
}

.container-carousel {
  align-self: center;
  object-fit: cover;
  margin: auto;
  max-width: none;
  width: 100%;
  align-items: center;

  .context-img {
    width: 750px;
  }

  img {
    height: 100%;
    align-self: center;
    object-fit: cover;
    margin: auto;
    max-height: 520px !important;
    max-width: 1080px !important;
    min-height: 200px !important;
  }

  .w-100 {
    width: auto !important;
    max-width: 1080px !important;
  }

  @media (max-width: 991px) {
    max-width: none;
    width: 100%;

    .responive-slider-img {
      img {
        height: 100%;
        align-self: center;
        object-fit: cover;
        margin: auto;
        //max-height: 320px !important;
        max-width: 1080px !important;
        //min-height: 220px !important;
      }

      .w-100 {
        width: 320px !important;
        max-width: 1080px !important;
        object-fit: cover;
        max-height: 320px !important;
        max-width: 1080px !important;
        min-width: 100% !important;
        min-height: 200px !important;
      }
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-color: #7cb4f4;
      border-radius: 20px;
      padding: 20px;
      background-position: center;
      background-size: 50%;
    }
  }
}

.container-float {
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
  background: white;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  min-height: 65px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 1em;
  padding-bottom: 1em;

  .button-contact {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 8px, rgba(0, 0, 0, 0.12) 0px 5px 8px;
    background-color: #f24182;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 2px;
    top: 0px;
    border-radius: 8;
    margin-top: 10px;
    margin-left: 7px;
    margin-right: 7px;
  }

  .button-call {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 8px, rgba(0, 0, 0, 0.12) 0px 5px 8px;
    background-color: rgb(46, 190, 17);
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 2px;
    top: 0px;
    border-radius: 8;
  }

  .button-whatsapp {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 8px, rgba(0, 0, 0, 0.12) 0px 5px 8px;
    background-color: #f1ebed;
    color: rgb(46, 190, 17);
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 2px;
    top: 0px;
    border-radius: 8;
  }

  @media (min-width: 992px) {
    display: none;
  }
}

.header-code {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 8px, rgba(0, 0, 0, 0.12) 0px 5px 8px;
  height: 60px;

  span {
    font-size: 24px;
    margin: 10px;
    color: #7a8790;
    // dfont-weight: 100;
  }
}

.container-price {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 8px, rgba(0, 0, 0, 0.12) 0px 5px 8px;
  margin-top: 40px;
  text-align: center;
}

.contact.card {
  margin-bottom: 89px;
}

.contact {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 8px, rgba(0, 0, 0, 0.12) 0px 5px 8px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 15px;
  text-align: center;

  .header-contact {
    span {
      color: white;
      font-size: 18px;
      top: 10px;
      left: 0px;
      right: 0px;
      position: absolute;
      text-align: center;
    }

    padding: 16px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    height: 80px;
    background: linear-gradient(rgb(35, 37, 38), rgb(65, 67, 69));
    position: relative;
  }

  .footer-company-contact {
    span {
      color: white;
      font-size: 18px;
      top: 10px;
      left: 0px;
      right: 0px;
      position: absolute;
      text-align: center;
    }

    margin-top: 10px;
    padding: 26px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    background: linear-gradient(rgb(35, 37, 38), rgb(65, 67, 69));
    position: relative;
  }

  .body-contact {
    p {
      padding-top: 15px;
    }

    margin-top: 20px;
  }

  .button-form {
    margin: 10px;

    button {
      box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 8px,
        rgba(0, 0, 0, 0.12) 0px 5px 8px;
      background-color: $secondary-shade1;
      border: none;
      font-family: Arial, Helvetica, sans-serif;
      border-radius: 2px;
      top: 0px;
    }
  }

  .footer-contact {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 8px, rgba(0, 0, 0, 0.12) 0px 5px 8px;
    background-color: rgb(46, 190, 17);
    font-family: Arial, Helvetica, sans-serif;
    margin: 10px;
  }

  .img-circule {
    color: rgb(255, 255, 255);
    background: white;
    user-select: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    border-radius: 50%;
    height: 72px;
    width: 72px;
    object-fit: cover;
    margin-top: 25px;
    border: 2px solid;
  }

  button {
    background-color: $secondary-button;

    border-radius: 2px;
    top: 0px;
  }
}