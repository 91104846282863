.funnel-header {
  position: absolute !important;
  width: 100%;
  top: 0;
  left: 0;
  background: transparent !important;

  .header-container {
    margin-bottom: 0 !important;
  }
}

.header {
  position: relative;
  background: #f5f5f5;
  .background {
    width: 100%;
  }

  .user-logo {
    margin-left: 10px;
    margin-top: 10px;
    height: 60px;
    width: 60px;
    object-fit: cover;
    border-radius: 50%;
  }
  .user-company {
    text-align: flex-start;
    color: rgb(240, 242, 244);
    margin-top: 10px;
    margin-left: 16px;
    p {
      font-size: 22px;
      margin-bottom: 0px !important;
    }
  }
  .user-name {
    text-align: flex-start;
    color: rgb(240, 242, 244);
    margin-left: 16px;
    p {
      font-size: 15px;
      margin-bottom: 0px !important;
    }
  }
  .header-container {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
  }
  .info-user-header {
    display: inline-flex;
    justify-content: flex-start;
    width: 100%;
  }
  .appbar-title {
    color: rgb(240, 242, 244);
    margin-left: 16px;
    margin-top: 16px;
  }
  .appbar-description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
    padding-top: 0px;
    letter-spacing: 0px;
    font-size: 24px;
    font-weight: 400;
    height: 8px;
    text-align: left;
  }
  @media only screen and (max-width: 1280em) {
    .background {
      display: none;
    }
    .header-container {
      position: relative;
      background: radial-gradient(
        71.19% 285.35% at 0% 129.17%,
        #59acf9 0%,
        #84bef4 100%
      );
      margin-bottom: 30px;
    }
  }
}

.colorBackground {
  .header-container {
    background: green;
  }
}
