@import '../../assets/css/variables.scss';
.footer-table{
    padding: 8px 0px;
    width: 100%;
    font-weight: 500;
    text-align: left;
    font-size: 16px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    .total-pages{
        margin: 5px;
    }
    .prev-button button,
    .next-button button{
      background: $primary;
      border: none;
      color: #ffffff;
      padding: 5px 15px;
    }
}