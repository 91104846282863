@import '../../assets/css/variables.scss';

.filter {

    .filter__dropdown {
        -webkit-text-size-adjust: 100%;
        font-size: 14px;
        line-height: 1.42857;
        text-align: center;
        outline: none!important;
        color: rgba(0, 0, 0, 0.87);
        background-color: rgb(255, 255, 255);
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        box-sizing: border-box;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        box-shadow: rgba(0, 0, 0 , 0.12) 0px 5px 8px, rgba(0, 0, 0 , 0.12) 0px 5px 8px;
        border-radius: 2px;
        z-index: 1;
        max-width: 270px;
    }
    @media (min-width: 768px) {
        .filter__dropdown {
            display: block;
        }
    }

    @media only screen and (max-width: 1280em) {
        .filter__dropdown {
            display: block;
        }
      }

    .filter__subtitle{
        margin-top: 10px;
        margin-bottom: 15px;
        text-align: center;
        font-size: 14px; 
        color: rgba(0, 0, 0, 0.87);
    }

    .filter__select {
        height: auto;
        line-height: 56px;
        overflow: hidden;
        opacity: 1;
        position: relative;
        padding-left: 0px;
        padding-right: 56px;
        padding: 16px;
        text-overflow: ellipsis;
        top: 6px;
        white-space: nowrap;
        font-size: 13px;
        line-height: 24px;
        width: 100%;
        display: inline-block;
        position: relative;
        background-color: transparent;
        font-family: 'Roboto';
        transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        cursor: pointer;
        text-align: left;
    }

    .filter__input{
        appearance: textfield;
        padding: 16px;
        position: relative;
        width: 100%;
        border: none;
        outline: none;
        background-color: rgba(0, 0, 0, 0);
        color: rgba(0, 0, 0, 0.87);
        cursor: inherit;
        font: inherit;
        opacity: 1;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        height: 100%;
        box-sizing: border-box;
        margin-top: 14px;
    }
    
    .filter__checkbox{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 10px;
    }
    .filter__button__search{
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        top: 0px;
        color: white;
        background-color: $primary;
        font-weight: 400;
        margin-top: 15px;
    }
    .filter__button__clean{
        div {
            display: flex;
          }
        span{
            color: rgba(0, 0, 0, 0.87);
            display: inline-flex;
            font-size: 15px;
            font-weight: 400;
        }
       
        button{
            margin-top: 10px;
            border-radius: 2px;
            margin-left: 20px;
            color: white;
            background-color: $secondary;
            max-width: 100px;
            max-height: 44px;
            font-weight: 400;
        }
        
    }
}

.filter_button_clean{
    @media (max-width: 768px) {
        display: flex;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 99;
        background: #fff;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        min-height: 65px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-top: 1em;
        padding-bottom: 1em;
    }
    .filter_container_button_clean{
        color: rgba(0, 0, 0, 0.87);
        background-color: rgb(255, 255, 255);
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        box-sizing: border-box;
        font-family: Roboto, sans-serif;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0 ,0, 0.12) 0px 1px 4px;
        border-radius: 3px;
        margin: 0px;
        height: 33px;
        width: 100%;
        margin: 1em;


        button{
            border: 10px;
            font-family: Roboto, sans-serif;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            cursor: pointer;
            text-decoration: none;
            margin: 0px;
            padding: 0px;
            outline: none;
            font-size: inherit;
            font-weight: inherit;
            position: relative;
            height: 33px;
            line-height: 33px;
            border-radius: 2px;
            transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
            background-color: rgb(255, 64, 129);
            text-align: center;
        }
    }
    span{
        color: rgba(0, 0, 0, 0.87);
        display: inline-flex;
        font-size: 15px;
        font-weight: 400;
    }
    
}