body {
  background: url('../../assets/image/backgroundWhatsapp.jpeg');
  background-size: contain;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.full-height {
  height: 100vh;
  padding-top: 75px;
}

.react-player {
  width: 100% !important;
  height: calc(55vw) !important;
}

.container-center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.water-mark {
  display: flex;
  position: fixed;
  bottom: 5px;
  left: 0;
  width: 100%;
  a {
    text-align: center;
    width: 100%;
    color: gray;
  }
}

.container-type {
  margin-top: 10%;
  margin-left: 30%;
  position: relative;
}

.text {
  margin-top: 30px;
}

.buttonItem {
  display: flex;
  width: 250px;
  height: auto;
  margin-left: 0px;
  margin-top: 10px;
  border-radius: 25px;
  p {
    margin-left: 10px;
  }
  .borderRadiusText:hover {
    background-color: white;
  }
}

@media only screen and (max-width: 400px) {
  .buttonItem {
    width: auto;
    min-width: 150px;
  }
  .container-center {
    .sub-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .name-title {
      width: 100%;
      text-align: start;
    }
    padding: 0 30px;
    align-items: flex-start;
  }
}

.buttonItem:hover {
  background-color: #128c7e;
  .borderRadiusText {
    background-color: #128c7e;
  }
}
.buttonNext {
  margin-top: 20px;
  width: 250px;
  border-radius: 20px;
}

.buttonNext:hover {
  background-color: #128c7e;
}

.buttonItem:active {
  background-color: #128c7e;
}

.borderRadiusText {
  border: 1px solid #128c7e;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 30px;
}

.outline-success {
  background-color: #128c7e;
}

.outline-success:hover {
  background-color: #128c7e;
}

.btn-success {
  background-color: #128c7e;
}

.hide-on-mobile {
  .buttonNext {
    margin-right: 20px;
  }
}

.react-video {
  width: 500px;
  height: 400px;
}

.show-on-mobile {
  .buttonItem {
    width: 100%;
    margin-left: auto;
  }
  .buttonNext {
    width: -webkit-fill-available;
  }
  .react-video {
    width: auto;
  }
}

.form-control-white {
  border: none !important;
  border-radius: 0;
  border-bottom: 1px solid rgba(17, 140, 126, 0.5) !important;
  font-size: 20px;
  padding: 5px 0;
  margin-top: 10px;
  width: 100%;
  &::placeholder {
    color: rgba(17, 140, 126, 0.5);
    opacity: 1;
  }
  &:focus {
    box-shadow: none;
  }
}
