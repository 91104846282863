@import 'assets/css/variables.scss';

// Typography
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: $xxxlarge;
  font-weight: $bold;
  font-family: 'NotoSans-Thin';
  color: rgb(121, 121, 121);
  margin: 0;
}

h2 {
  font-size: $xxlarge;
  font-weight: $bold;
  margin: 0;
}

h3 {
  font-size: $xlarge;
  font-weight: $bold;
  margin: 0;
}

p {
  font-size: $large;
  font-weight: $medium;
  margin: 0;
  font-family: 'Roboto-Thin', sans-serif;
}

option {
  font-weight: $light;
  font-family: 'NotoSans-Thin';
  color: rgb(121, 121, 121);
}

div {
  font-size: $small;
  font-weight: $medium;
  margin: 0;
  font-family: 'Roboto-Thin', sans-serif;
  color: rgb(121, 121, 121);
}

span {
  font-size: $small;
  font-weight: $fmedium;
  margin: 0;
}

.paragraph-small {
  font-size: $medium;
}

.span-small {
  font-size: $xsmall;
  font-weight: $regular;
}

.light {
  font-weight: $light;
}

.regular {
  font-weight: $regular;
}

button {
  padding: 13px 0;
  width: 100%;
  border-radius: 10px;
  border: none;
  font-size: $large;
  font-weight: $light;
  color: $default;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  &:disabled {
    background: $muted !important;
    cursor: not-allowed;
    color: black;
  }
}

.gradient {
  background: radial-gradient(
    71.19% 285.35% at 0% 129.17%,
    #59acf9 0%,
    #84bef4 100%
  );
}

.outline {
  background: transparent;
  border: solid 2px $muted;
  color: $muted;
  width: auto;
  padding: 10px;
}

.button-warning {
  background: $warning;
  color: $default;
  width: auto;
  padding: 10px;
}

.button-cancel {
  background: $secondary;
  color: $default;
  width: auto;
  padding: 10px;
}

.table-card {
  background: radial-gradient(
      71.19% 285.35% at 0% 129.17%,
      #59acf9 0%,
      #84bef4 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border-radius: 21px;
  padding: 40px;
  margin-bottom: 30px;

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      color: $default;
      font-weight: $regular;
      font-size: $medium;
    }

    tbody {
      tr {
        border-bottom: solid 2px $default;
      }
    }

    tbody {
      tr {
        border-bottom: solid 2px $default;
      }
      td {
        text-align: center;
        color: $default;
        font-weight: $fmedium;
        font-size: $large;
        padding: 20px 0;
      }
    }

    .green {
      color: $success;
    }

    .red {
      color: $danger;
    }

    .rate-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .status-icon {
      margin-top: 3px;
      width: 19px;
      height: 19px;
    }

    .cripto-icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
    .cripto-select {
      font-family: 'Outfit', sans-serif;
      background: transparent;
      font-size: $large;
      color: $default;
      font-weight: $fmedium;
      outline: none;
    }
    .flag-currency {
      font-size: $large;
      color: $warning;
      font-weight: $fmedium;
    }
    .td-container {
      display: flex;
      justify-content: center;
    }
    .input-container {
      width: 140px;
      position: relative;
      display: flex;

      input {
        width: 100%;
        border-radius: 9px;
        border: solid 1px $default;
        color: $default;
        font-size: $large;
        font-weight: $fmedium;
        background: transparent;
        padding: 5px 49px 5px 5px;
      }

      .flag-currency {
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }
  }

  @media only screen and (max-width: 64em) {
    table {
      width: 1000px;
    }
    overflow-x: auto;
  }

  .add-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .add-rm-icon {
      cursor: pointer;
      height: 40px;
      width: 40px;
      &:hover {
        opacity: 0.5;
      }
      &:first-child {
        margin-right: 20px;
      }
    }
  }

  .action-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    button {
      &:first-child {
        margin-right: 50px;
      }
    }
  }
}

.paginator {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  img {
    height: 30px;
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
  .item {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $fmedium;
    font-size: $large;
    color: $primary;
    border-radius: 50px;
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
  .selected {
    background: rgba(190, 191, 238, 0.3);
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 788px) {
  .show-on-mobile {
    display: none !important;
  }
}

@media (max-width: 788px) {
  .hide-on-mobile {
    display: none !important;
  }
}
